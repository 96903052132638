import React from 'react'
import upperCase from 'lodash/upperCase'

export const colors = {
    workbench: 'bg-green-100 text-green-800',
    tools: 'bg-yellow-100 text-yellow-800',
    casting: 'bg-blue-100 text-blue-800',
    wax: 'bg-red-100 text-red-800',
    classes: 'bg-purple-100 text-purple-800',
    tips: 'bg-gray-100 text-gray-800'
}

const Tag = ({tag}) => {
    return <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${colors[tag]}`}>
        {upperCase(tag)}
    </span>;
}

export const salesTagColors = {
    beginner: 'bg-green-100 text-green-800',
    intermediate: 'bg-yellow-100 text-yellow-800',
    advanced: 'bg-red-100 text-yellow-800',
    rings: 'bg-blue-100 text-blue-800',
    gemstones: 'bg-purple-100 text-purple-800'
}


export const SalesTag = ({tag}) => {
    return <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${salesTagColors[tag]}`}>
        {upperCase(tag)}
    </span>;
}


export default Tag
