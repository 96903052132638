import { Link } from "gatsby";
import { classes2022, overview } from "../content/classes";
import React, { useMemo, useState } from "react";
import { JoinNewsLetterButton } from "../components/Common/NewsLetterPlug";
import kebabCase from "lodash/kebabCase";
import ReactTooltip from "react-tooltip";
import Layout from "../components/layout";
import { PageTitle } from "../components/Common/PageTitle";
import { SalesTag, salesTagColors } from "../components/Blog/Tag";
import capitalize from "lodash/capitalize";

const Tags = ({ selected, classes, onSelect }) => {
  const displayables = { all: "bg-gray-100 text-gray-800", ...salesTagColors };
  return (
    <div className="mt-12">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Filter by tags
        </label>
        <select
          id="tabs"
          name="tabs"
          value={selected}
          onChange={(e) => {
            onSelect(e.target.value);
          }}
          className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
        >
          {Object.entries(displayables).map(([tag]) => {
            const amount = classes.filter(
              (it) => tag === "all" || (it.tags && it.tags.includes(tag)),
            ).length;
            return (
              <option key={tag} value={tag}>{`${capitalize(
                tag,
              )} (${amount})`}</option>
            );
          })}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-4" aria-label="Tabs">
            {Object.entries(displayables).map(([tag, color]) => {
              const amount = classes.filter(
                (it) => tag === "all" || (it.tags && it.tags.includes(tag)),
              ).length;
              return (
                <div
                  key={tag}
                  onClick={() => onSelect(tag)}
                  className="cursor-pointer"
                >
                  <span
                    className={`px-3 py-1 font-medium text-sm rounded-t-md ${color} ${
                      tag === selected ? `underline` : undefined
                    }`}
                  >
                    {capitalize(tag)}
                    <span className="bg-gray-100 text-gray-900 hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block">
                      {amount}
                    </span>
                  </span>
                </div>
              );
            })}
          </nav>
        </div>
      </div>
    </div>
  );
};

const ClassCard = ({
  image,
  title,
  description,
  timeframe,
  disabled,
  tooltip,
  tags = [],
  onSelectTag,
  salesPageUrl,
}) => (
  <div
    className="space-y-4 bg-gray-50 shadow-lg rounded-lg"
    data-tip={disabled ? tooltip : undefined}
  >
    <div className="aspect-w-6 aspect-h-5">
      <img
        className={`object-cover rounded-t-lg ${disabled ? "opacity-25" : ""}`}
        src={image}
        alt={title}
      />
    </div>

    <div className="space-y-2">
      <div className="px-4 sm:px-6 text-lg leading-6 font-medium space-y-1">
        <h3 className="text-gray-900">{title}</h3>
      </div>
      <div className="px-4 pt-1 pb-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-2 mb-5">
          <div className="sm:col-span-2">
            <dd className="mt-1  prose prose-indigo text-gray-700 mb-8 h-24">
              {description}
            </dd>
          </div>
        </dl>
        <div className="border-t border-gray-200 px-4 py-4 sm:px-6">
          <div className="max-w-screen-xl mx-auto my-auto text-center">
            <div className="flex justify-center align-middle">
              {!disabled ? (
                <div className="inline-flex rounded-md shadow">
                  <Link
                    to={salesPageUrl ?? `/class/${kebabCase(title)}`}
                    className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-700 hover:bg-blue-500 focus:outline-none focus:ring transition duration-150 ease-in-out"
                  >
                    More information
                  </Link>
                </div>
              ) : (
                <div className="inline-flex rounded-md">
                  <span className="inline-flex items-center justify-center px-5 py-3 text-base leading-6 font-medium rounded-md text-blue-700">
                    Coming in {timeframe}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-6 flex items-center">
          <div className="ml-3">
            {tags.map((it) => (
              <div
                key={it}
                className="cursor-pointer"
                onClick={() => onSelectTag(it)}
              >
                <SalesTag tag={it} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Classes = () => {
  const classes = [...overview.classes, ...classes2022]
    .filter((it) => !it.freebie)
    .map((it) => ({
      ...it,
      disabled: !it.salesPageReleased,
    }));

  const [selected, setSelected] = useState(undefined);
  const filteredClasses = useMemo(
    () =>
      classes.filter(
        (it) =>
          !selected ||
          selected === "all" ||
          (it.tags && it.tags.includes(selected)),
      ),
    [selected, classes],
  );

  return (
    <Layout
      description="In the online classes, you learn how to carve your own piece of jewellery from wax. Ready to be cast in the metal of your choice.
    Classes include videos, showing you exactly how to make the piece of jewellery. Written handouts are provided. And every Monday I answer your questions."
      title="Wax Carvers - Classes"
    >
      <div className="relative pt-16 pb-6 px-4 sm:px-6 lg:pt-24 lg:px-8">
        <div className="absolute inset-0">
          <div className="bg-white h-1/3 sm:h-2/3"></div>
        </div>
        <div className="max-w-7xl mx-auto relative">
          <PageTitle title="Classes" subtitle="" />
          <Tags selected={selected} classes={classes} onSelect={setSelected} />
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            {filteredClasses.map((it) => (
              <ClassCard
                {...it}
                key={it.timeframe + it.title}
                onSelectTag={setSelected}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="mt-12">
        <JoinNewsLetterButton />
      </div>
      <ReactTooltip />
    </Layout>
  );
};

export default Classes;
